/*@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,900,900i|Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap');*/

html, body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
}
body {
  position: relative
}
/*.App {*/
/*  text-align: left;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*}*/

/*.App-header {*/
/*  background-color: white;*/
/*  height: 50vh;*/
/*  width: 50vw;*/
/*  margin: 25vh 25vw;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: flex-start;*/
/*  justify-content: flex-start;*/
/*  color: black;*/
/*}*/

/*.App-link {*/
/*  color: blue;*/
/*}*/
