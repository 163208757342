@import url('https://fonts.googleapis.com/css?family=EB+Garamond:400,400i,500,500i,600,600i,700,700i,800,800i|Open+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

html {
  scroll-padding-top: 112px; /* height of sticky header */
}

html, body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
}
body {
  position: relative;
  height: 100vh;
  background: center / 20px 20px repeat url("../public/icon/diamond.svg") fixed;
}

/* Standard Syntax */
@keyframes rainbow {
  0% {
    color: blue;
  }
  50% {
    color: deeppink;
  }
  100% {
    color: blue;
  }
}

/* Standard Syntax */
@-webkit-keyframes webkit-rainbow {
  0% {
    color: blue;
  }
  50% {
    color: deeppink;
  }
  100% {
    color: blue;
  }
}

:root {
  --rainbow: rainbow;
  --webkitRainbow: webkit-rainbow;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: white;
  height: 50vh;
  width: 50vw;
  /*margin: 25vh 25vw;*/
  /*display: flex;*/
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: black;
}

.App-link {
  color: blue;
}

a {
  text-decoration: none;
  -webkit-text-fill-color: blue;
  -webkit-text-stroke-color: #ffffff;
}

a:visited {
  text-decoration: none;
  -webkit-text-fill-color: blue;
  -webkit-text-stroke-color: #ffffff;
}

a:hover {
  text-decoration: none;
  -webkit-text-fill-color: var(--webkitRainbow);
  color: var(--rainbow);
  -webkit-text-stroke-color: #ffffff;
  -webkit-animation: rainbow 1s infinite;
  animation: rainbow 1s infinite;
}

a:active {
  text-decoration: none;
  -webkit-text-fill-color: deeppink;
  -webkit-text-stroke-color: #ffffff;
}

@media all and (max-width: 600px) and (min-width: 0px) {
  h1 a {
    text-decoration: none;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke-color: deeppink;
  }

  h1 a:visited {
    text-decoration: none;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke-color: deeppink;
  }

  h1 a:hover {
    text-decoration: none;
    -webkit-text-fill-color: var(--webkitRainbow);
    color: var(--rainbow);
    -webkit-text-stroke-color: deeppink;
    -webkit-animation: rainbow 1s infinite;
    animation: rainbow 1s infinite;
  }

  h1 a:active {
    text-decoration: none;
    -webkit-text-fill-color: deeppink;
    -webkit-text-stroke-color: #ffffff;
  }
}
